import {jsx as _jsx} from "react/jsx-runtime"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as Sentry from '@sentry/browser'
import {Component,} from 'react'

import useAtmosphere from '~/hooks/useAtmosphere'
import SendClientSideEvent from '~/utils/SendClientSideEvent'
import {isOldBrowserError} from '../utils/isOldBrowserError'
import ErrorComponent from './ErrorComponent/ErrorComponent'













class ErrorBoundary extends Component {constructor(...args) { super(...args); ErrorBoundary.prototype.__init.call(this); }
  __init() {this.state = {
    error: undefined,
    errorInfo: undefined,
    eventId: undefined,
    isOldBrowserErr: false
  }}

  componentDidUpdate() {
    const {error, isOldBrowserErr} = this.state
    if (!error || isOldBrowserErr) return
    const {atmosphere} = this.props
    SendClientSideEvent(atmosphere, 'Fatal Error')
  }

  componentDidCatch(error, errorInfo) {
    const {atmosphere} = this.props
    const {viewerId} = atmosphere
    const store = atmosphere.getStore()
    const email = _nullishCoalesce(_optionalChain([(_optionalChain([store, 'optionalAccess', _ => _.getSource, 'optionalCall', _2 => _2(), 'access', _3 => _3.get, 'optionalCall', _4 => _4(viewerId)]) ), 'optionalAccess', _5 => _5.email]), () => ( ''))
    const isOldBrowserErr = isOldBrowserError(error.message)
    if (viewerId) {
      Sentry.configureScope((scope) => {
        scope.setUser({email, id: viewerId})
      })
    }
    // Catch errors in any components below and re-render with error message
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo )
      scope.setLevel(Sentry.Severity.Fatal)
      const eventId = Sentry.captureException(error)
      this.setState({
        error,
        errorInfo,
        eventId,
        isOldBrowserErr
      })
    })
  }

  render() {
    const {error, eventId} = this.state
    if (error && eventId) {
      const {fallback} = this.props
      return fallback ? (
        fallback(error, eventId)
      ) : (
        _jsx(ErrorComponent, { error: error, eventId: eventId,} )
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

const ErrorBoundaryFn = (props) => {
  const atmosphere = useAtmosphere()
  return _jsx(ErrorBoundary, { ...props, atmosphere: atmosphere,} )
}

export default ErrorBoundaryFn
