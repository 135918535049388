import {jsx as _jsx} from "react/jsx-runtime";import * as Tooltip from '@radix-ui/react-tooltip'
import {BrowserRouter as Router} from 'react-router-dom'
import Action from './components/Action/Action'
import AtmosphereProvider from './components/AtmosphereProvider/AtmosphereProvider'
import './styles/theme/global.css'

export default function Root() {
  return (
    _jsx(AtmosphereProvider, { children: 
      _jsx(Router, { children: 
        _jsx(Tooltip.Provider, { children: 
          _jsx(Action, {} )
        })
      })
    })
  )
}
