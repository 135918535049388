import {jsxs as _jsxs} from "react/jsx-runtime"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from 'react'
import { createContext, useCallback, useContext, useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import requestDoubleAnimationFrame from '../components/RetroReflectPhase/requestDoubleAnimationFrame'
import hideBodyScroll from '../utils/hideBodyScroll'
import useEventCallback from './useEventCallback'
import useRefState from './useRefState'

export var PortalStatus; (function (PortalStatus) {
  const Mounted = 0; PortalStatus[PortalStatus["Mounted"] = Mounted] = "Mounted"; // node appended to DOM
  const Entering = Mounted + 1; PortalStatus[PortalStatus["Entering"] = Entering] = "Entering"; // 2 animation frames after appended to DOM
  const Entered = Entering + 1; PortalStatus[PortalStatus["Entered"] = Entered] = "Entered"; // animation complete
  const Exiting = Entered + 1; PortalStatus[PortalStatus["Exiting"] = Exiting] = "Exiting"; // closePortal was called
  const Exited = Exiting + 1; PortalStatus[PortalStatus["Exited"] = Exited] = "Exited"; // initial state
})(PortalStatus || (PortalStatus = {}));

 










































const PortalContext = createContext(undefined)

const getParent = (parentId) => {
  const parent = parentId ? document.getElementById(parentId) : document.body
  if (!parent) throw new Error('Could not find parent ' + parentId)
  return parent
}

/**
 * Create and manage a React.Portal to display nodes outside the DOM. Manages Keyboard presses etc.
 * To nest multiple portals, the outer one should have id set and the inner one parentId
 */
const usePortal = (options = {}) => {
  const portalRef = useRef()
  const originRef = useRef()
  const timeoutRef = useRef(null)
  const showBodyScroll = useRef()
  const [portalStatusRef, setPortalStatus] = useRefState(PortalStatus.Exited)
  const contextParentId = useContext(PortalContext)
  const parentId = _nullishCoalesce(options.parentId, () => ( contextParentId))

  const terminatePortal = useEventCallback(() => {
    if (!portalRef.current) return
    document.removeEventListener('keydown', handleKeydown)
    document.removeEventListener('mousedown', handleDocumentClick)
    document.removeEventListener('touchstart', handleDocumentClick)
    setPortalStatus(PortalStatus.Exited)
    try {
      getParent(parentId).removeChild(portalRef.current)
    } catch (e) {
      /* portal already removed (possible when parent is not document.body) */
    }
    portalRef.current = undefined
    showBodyScroll.current && showBodyScroll.current()
    timeoutRef.current = null
  })

  // terminate on unmount
  useEffect(() => terminatePortal, [terminatePortal])

  const terminateAfterTransition = useEventCallback(() => {
    // setTimeout because the portal should terminate only after all other transitionend events had time to fire
    timeoutRef.current = window.setTimeout(terminatePortal)
  })

  const closePortal = useEventCallback(() => {
    if (portalStatusRef.current >= PortalStatus.Exiting) return
    document.removeEventListener('keydown', handleKeydown)
    document.removeEventListener('mousedown', handleDocumentClick)
    document.removeEventListener('touchstart', handleDocumentClick)
    if (portalRef.current) {
      portalRef.current.addEventListener('transitionend', terminateAfterTransition)
    }
    setPortalStatus(PortalStatus.Exiting)
    // important! this should be last in case the onClose also tries to close the portal (see EmojiMenu)
    options.onClose && options.onClose()
  })

  const handleKeydown = useEventCallback((e) => {
    if (e.key === 'Escape') {
      const children = Array.from(_nullishCoalesce(_optionalChain([portalRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.children]), () => ( [])))
      const hasChildModal = children.some((child) => child.id)
      if (hasChildModal) return
      const {activeElement, body} = document
      if (activeElement !== body && activeElement instanceof HTMLElement) {
        const {contentEditable, tagName} = activeElement
        // if viewer is typing something, don't close the portal on escape
        if (tagName === 'INPUT' || tagName === 'TEXTAREA' || contentEditable === 'true') {
          return
        }
      }
      // give focus back to the thing that opened it
      _optionalChain([originRef, 'access', _3 => _3.current, 'optionalAccess', _4 => _4.focus, 'call', _5 => _5()])
      closePortal()
    }
  })

  const handleDocumentClick = useEventCallback((e) => {
    if (!portalRef.current) return
    const target = e.target 
    if (!portalRef.current.contains(target)) {
      // clicks on the toggle must be ignored, otherwise a long click where mouseup occurs after close will trigger a reopen
      if (!originRef.current || !originRef.current.contains(target)) {
        closePortal()
      }
    }
  })

  const openPortal = useEventCallback((e) => {
    const {current: portalStatus} = portalStatusRef
    if (portalStatus <= PortalStatus.Entered) return

    if (!options.allowScroll) {
      showBodyScroll.current = hideBodyScroll()
    }
    if (!options.noClose) {
      document.addEventListener('keydown', handleKeydown)
      document.addEventListener('mousedown', handleDocumentClick)
      document.addEventListener('touchstart', handleDocumentClick)
    }
    if (portalStatus === PortalStatus.Exiting) {
      if (portalRef.current) {
        portalRef.current.removeEventListener('transitionend', terminateAfterTransition)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      setPortalStatus(PortalStatus.Entered)
    } else if (portalStatus === PortalStatus.Exited) {
      setPortalStatus(PortalStatus.Mounted)
      // without rDAF: 1) coords may not be updated (if useCoords is used), 2) `enter` class hasn't had time to flush (if animations are used)
      requestDoubleAnimationFrame(() => {
        setPortalStatus(PortalStatus.Entering)
      })

      portalRef.current = document.createElement('div')
      portalRef.current.id = options.id || 'portal'
      getParent(parentId).appendChild(portalRef.current)
      if (_optionalChain([e, 'optionalAccess', _6 => _6.currentTarget])) {
        originRef.current = e.currentTarget 
      }
      _optionalChain([options, 'access', _7 => _7.onOpen, 'optionalCall', _8 => _8(portalRef.current)])
    }
  })

  const togglePortal = useEventCallback((e) => {
    portalRef.current ? closePortal() : openPortal(e)
  })

  const portal = useCallback(
    (reactEl) => {
      const targetEl = portalRef.current
      return !targetEl || portalStatusRef.current === PortalStatus.Exited
        ? null
        : createPortal(
            _jsxs(PortalContext.Provider, { value: options.id, children: [reactEl, " " ]}),
            targetEl
          )
    },
    [portalRef, portalStatusRef]
  )

  return {
    openPortal,
    closePortal,
    terminatePortal,
    togglePortal,
    portal,
    portalStatus: portalStatusRef.current,
    setPortalStatus
  }
}

export default usePortal
