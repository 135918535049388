import {jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs} from "react/jsx-runtime";import {lazy, memo, Suspense} from 'react'
import 'react-day-picker/dist/style.css'
import {Route, Switch} from 'react-router'
import useServiceWorkerUpdater from '../../hooks/useServiceWorkerUpdater'
import useTrebuchetEvents from '../../hooks/useTrebuchetEvents'
import {GlobalBanner, LoaderSize} from '../../types/constEnums'
import {CREATE_ACCOUNT_SLUG, SIGNIN_SLUG} from '../../utils/constants'
import ErrorBoundary from '../ErrorBoundary'
import Banner from '../GlobalBanner'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import PrivateRoutes from '../PrivateRoutes'
import Snackbar from '../Snackbar'

const AnalyticsPage = lazy(() => import(/* webpackChunkName: 'AnalyticsPage' */ '../AnalyticsPage'))
const AuthenticationPage = lazy(
  () => import(/* webpackChunkName: 'AuthenticationPage' */ '../AuthenticationPage')
)
const DemoMeeting = lazy(() => import(/* webpackChunkName: 'DemoMeeting' */ '../DemoMeeting'))
const DemoSummary = lazy(() => import(/* webpackChunkName: 'DemoSummary' */ '../DemoSummary'))
const AuthProvider = lazy(() => import(/* webpackChunkName: 'AuthProvider' */ '../AuthProvider'))
const SAMLRedirect = lazy(() => import(/* webpackChunkName: 'SAMLRedirect' */ '../SAMLRedirect'))
const SetNewPassword = lazy(
  () => import(/* webpackChunkName: 'SetNewPassword' */ '../ResetPasswordPage/SetNewPassword')
)
const VerifyEmail = lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '../VerifyEmail'))
const TeamInvitation = lazy(
  () => import(/* webpackChunkName: 'TeamInvitationRoot' */ '../TeamInvitationRoot')
)
const InvitationLink = lazy(
  () => import(/* webpackChunkName: 'InvitationLinkRoot' */ '../InvitationLinkRoot')
)

const Action = memo(() => {
  useTrebuchetEvents()
  useServiceWorkerUpdater()
  const isInternalAuthEnabled = window.__ACTION__.AUTH_INTERNAL_ENABLED
  // Global Banner
  const isGlobalBannerEnabled = window.__ACTION__.GLOBAL_BANNER_ENABLED
  const bannerText = window.__ACTION__.GLOBAL_BANNER_TEXT
  const bannerBgColor = window.__ACTION__.GLOBAL_BANNER_BG_COLOR
  const bannerColor = window.__ACTION__.GLOBAL_BANNER_COLOR
  return (
    _jsx(_Fragment, { children: 
      _jsxs(ErrorBoundary, { children: [
        _jsx(Snackbar, {} )
        , _jsxs(Suspense, { fallback: _jsx(LoadingComponent, { spinnerSize: LoaderSize.WHOLE_PAGE,} ), children: [
          _jsx(AnalyticsPage, {} )
          , isGlobalBannerEnabled && (
            _jsx(Banner, { bgColor: bannerBgColor, color: bannerColor, text: bannerText,} )
          )
          , _jsx('div', {
            className: "w-100 flex flex-col"  ,
            style: {
              height: isGlobalBannerEnabled ? `calc(100vh - ${GlobalBanner.HEIGHT}px)` : '100vh'
            },
 children: 
            _jsxs(Switch, { children: [
              _jsx(Route, { exact: true, path: "/", render: (p) => _jsx(AuthenticationPage, { ...p, page: 'signin',} ),} )
              , _jsx(Route, {
                exact: true,
                path: `/${SIGNIN_SLUG}`,
                render: (p) => _jsx(AuthenticationPage, { ...p, page: 'signin',} ),}
              )
              , _jsx(Route, {
                exact: true,
                path: `/${CREATE_ACCOUNT_SLUG}`,
                render: (p) => _jsx(AuthenticationPage, { ...p, page: 'create-account',} ),}
              )
              , _jsx(Route, { exact: true, path: `/auth/:provider`, component: AuthProvider,} )
              , _jsx(Route, { path: `/saml-redirect`, component: SAMLRedirect,} )
              , _jsx(Route, {
                path: "/retrospective-demo/:localPhaseSlug?/:stageIdxSlug?",
                component: DemoMeeting,}
              )
              , _jsx(Route, { path: "/retrospective-demo-summary/:urlAction?", component: DemoSummary,} )
              , isInternalAuthEnabled && (
                _jsx(Route, {
                  exact: true,
                  path: `/forgot-password`,
                  render: (p) => _jsx(AuthenticationPage, { ...p, page: 'forgot-password',} ),}
                )
              )
              , isInternalAuthEnabled && (
                _jsx(Route, {
                  path: `/forgot-password/submitted`,
                  render: (p) => _jsx(AuthenticationPage, { ...p, page: `forgot-password/submitted`,} ),}
                )
              )
              , _jsx(Route, {
                path: "/verify-email/:verificationToken/:invitationToken?",
                component: VerifyEmail,}
              )
              , _jsx(Route, { path: "/reset-password/:token", component: SetNewPassword,} )
              , _jsx(Route, { path: "/team-invitation/:token", component: TeamInvitation,} )
              , _jsx(Route, { path: "/invitation-link/:token", component: InvitationLink,} )
              , _jsx(Route, { component: PrivateRoutes,} )
            ]})
          })
        ]})
      ]})
    })
  )
})

Action.displayName = 'Action'

export default Action
