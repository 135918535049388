/*
 * Sometimes, we need the latest state, which means we need to keep it inside a container
 * but we also need to trigger rerenders, so we need to call useState or similar
 * calling useState is nice because it bails if called with the same input
 */
import { useCallback, useRef, useState} from 'react'

const useRefState = (
  initialState
) => {
  const [firstState, _setState] = useState(initialState)
  const latestState = useRef(firstState)
  const setState = useCallback((nextState) => {
    latestState.current = nextState
    _setState(nextState)
  }, [])
  return [latestState, setState]
}

export default useRefState
