 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import ms from 'ms'
import {useEffect, useRef} from 'react'
import {commitLocalUpdate} from 'react-relay'

import createProxyRecord from '../utils/relay/createProxyRecord'
import handleInvalidatedSession from './handleInvalidatedSession'
import useAtmosphere from './useAtmosphere'
import useRouter from './useRouter'

const useTrebuchetEvents = () => {
  const atmosphere = useAtmosphere()
  const {history} = useRouter()
  const firewallMessageSentRef = useRef(false)
  const recentDisconnectsRef = useRef([] )
  const serverVersionRef = useRef(__APP_VERSION__)

  useEffect(() => {
    const setConnectedStatus = (isConnected) => {
      commitLocalUpdate(atmosphere, (store) => {
        const root = store.getRoot()
        const viewer = root.getLinkedRecord('viewer')
        if (!viewer) {
          const tempViewer = createProxyRecord(store, 'User', {isConnected})
          root.setLinkedRecord(tempViewer, 'viewer')
        } else {
          viewer.setValue(isConnected, 'isConnected')
        }
      })
    }

    const onDisconnected = () => {
      setConnectedStatus(false)
      if (!firewallMessageSentRef.current) {
        const now = Date.now()
        recentDisconnectsRef.current = recentDisconnectsRef.current.filter(
          (time) => time > now - ms('1m')
        )
        recentDisconnectsRef.current.push(now)
        if (recentDisconnectsRef.current.length >= 4) {
          firewallMessageSentRef.current = true
          atmosphere.eventEmitter.emit('addSnackbar', {
            autoDismiss: 0,
            message: 'Your internet is unstable. Behind a firewall? Contact us for support',
            key: 'firewall'
          })
          return
        }
      }
      atmosphere.eventEmitter.emit('addSnackbar', {
        autoDismiss: 0,
        message: 'You’re offline, reconnecting…',
        key: 'offline'
      })
    }

    const onReconnected = () => {
      setConnectedStatus(true)
      atmosphere.eventEmitter.emit('removeSnackbar', ({key}) => key === 'offline')
    }

    const onData = async (payload) => {
      // hacky but that way we don't have to double parse huge json payloads. SSE graphql payloads are pre-parsed
      const obj = typeof payload === 'string' ? JSON.parse(payload) : payload

      if (obj.version) {
        if (obj.version !== serverVersionRef.current) {
          serverVersionRef.current = obj.version
          if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.getRegistration()
            _optionalChain([registration, 'optionalAccess', _ => _.update, 'call', _2 => _2(), 'access', _3 => _3.catch, 'call', _4 => _4()])
          }
        } else if (recentDisconnectsRef.current.length > 0) {
          // retry if reconnect and versions are the same
          atmosphere.retries.forEach((retry) => retry())
        }
      }
      if (obj.authToken) {
        atmosphere.setAuthToken(obj.authToken)
      }
    }

    const onClose = ({reason}) => {
      handleInvalidatedSession(reason, {atmosphere, history})
    }

    atmosphere.eventEmitter.once('newSubscriptionClient', () => {
      const {transport} = atmosphere
      const {trebuchet} = transport 
      trebuchet.on('reconnected' , onReconnected)
      trebuchet.on('disconnected' , onDisconnected)
      trebuchet.on('data' , onData)
      trebuchet.on('close' , onClose)
      setConnectedStatus(true)
    })
  }, [])
}

export default useTrebuchetEvents
