 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

import {LocalStorageKey, TrebuchetCloseReason} from '../types/constEnums'

export const closeMessages = {
  [TrebuchetCloseReason.SESSION_INVALIDATED]: 'You’ve been logged out from another device',
  [TrebuchetCloseReason.EXPIRED_SESSION]: 'Your session expired. Please log in'
}

const handleInvalidatedSession = (
  reason,
  {atmosphere, history}
) => {
  const message = closeMessages[reason]
  if (message) {
    window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
    atmosphere.eventEmitter.emit('addSnackbar', {
      key: 'logOutJWT',
      message,
      autoDismiss: 5
    })
    setTimeout(() => {
      atmosphere.close()
      _optionalChain([history, 'optionalAccess', _ => _.replace, 'call', _2 => _2('/')])
    })
  }
}

export default handleInvalidatedSession
