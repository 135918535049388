import {jsx as _jsx} from "react/jsx-runtime";import {Component,} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'
import Atmosphere from '../../Atmosphere'








class AtmosphereProvider extends Component {
  

  constructor(props) {
    super(props)
    if (props.getLocalAtmosphere) {
      this.loadDemo().catch()
    } else {
      this.atmosphere = new Atmosphere()
      this.atmosphere.getAuthToken(window)
    }
  }

  async loadDemo() {
    const LocalAtmosphere = await this.props.getLocalAtmosphere()
      .then((mod) => mod.default)
      .catch()
    this.atmosphere = new LocalAtmosphere()
    this.forceUpdate()
  }

  render() {
    if (!this.atmosphere) return null
    return (
      _jsx(RelayEnvironmentProvider, { environment: this.atmosphere, children: 
        this.props.children
      })
    )
  }
}

export default AtmosphereProvider
