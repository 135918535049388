

const SearchQueryId = {
  join: (service, meetingId) => `searchQuery:${service}:${meetingId}`,
  split: (id) => {
    const [, service, meetingId] = id.split(':')
    return {service, meetingId}
  }
}

export default SearchQueryId
