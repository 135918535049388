import {jsx as _jsx} from "react/jsx-runtime";import styled from '@emotion/styled'
import {DECELERATE} from '../styles/animation'
import {PALETTE} from '../styles/paletteV3'
import {Radius} from '../types/constEnums'
import PlainButton from './PlainButton/PlainButton'






const Action = styled(PlainButton)({
  borderRadius: Radius.SNACKBAR,
  color: PALETTE.ROSE_500,
  fontSize: 14,
  fontWeight: 600,
  marginLeft: 8,
  padding: 8,
  whiteSpace: 'nowrap',
  backgroundColor: '#ffffff17',
  transition: `background 100ms ${DECELERATE}`,
  ':hover,:focus,:active': {
    backgroundColor: '#ffffff26'
  }
})

const SnackbarMessageAction = (props) => {
  const {action} = props
  if (!action) return null
  const {label, callback} = action
  return _jsx(Action, { onClick: callback, children: label})
}

export default SnackbarMessageAction
