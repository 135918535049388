import * as amplitude from '@amplitude/analytics-browser'


const SendClientSideEvent = (
  atmosphere,
  event,
  options
) => {
  amplitude.track(event, options, {
    user_id: atmosphere.viewerId
  })
}

export default SendClientSideEvent
