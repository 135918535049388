




const initHandler = (store, payload) => {
  const {dataID, handleKey} = payload
  const record = store.get(dataID)
  if (!record) return
  const handleKeyValue = record.getValue(handleKey)
  if (handleKeyValue === undefined) {
    record.setValue(null, handleKey)
  }
}

export default initHandler
