

import clientTempId from './clientTempId'

const createProxyRecord = 





(
  store,
  type,
  record
) => {
  const id = record.id || clientTempId()
  const newRecord = store.create(id, type)
  newRecord.setValue(id, 'id')
  for (const [key, val] of Object.entries(record)) {
    // It's impossible to handle setLinkedRecords for empty arrays because we can't determine if it's a value or a link
    newRecord.setValue(val, key)
  }
  return newRecord 
}

export default createProxyRecord
