import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import styled from '@emotion/styled'
import {forwardRef,} from 'react'
import useModal from '~/hooks/useModal'
import DialogContent from './DialogContent'
import DialogTitle from './DialogTitle'
import MenuContents, {} from './MenuContents'
import PrimaryButton from './PrimaryButton'
import ReportErrorFeedback from './ReportErrorFeedback'






const ErrorBlock = styled(MenuContents)({
  background: '#fff',
  padding: 16
})

const Button = styled(PrimaryButton)({
  marginTop: 8
})

const ModalError = forwardRef((props, ref) => {
  const {error, eventId, ...blockProps} = props
  const {modalPortal, openPortal, closePortal} = useModal()
  return (
    _jsxs(ErrorBlock, { ...blockProps, ref: ref, children: [
      _jsx(DialogTitle, { children: "You found a bug!"   })
      , _jsxs(DialogContent, { children: [
        'We’ve alerted the developers. Try refreshing the page'
        , _jsx(Button, { onClick: openPortal, children: "Report Feedback" })
        , modalPortal(_jsx(ReportErrorFeedback, { closePortal: closePortal, eventId: eventId,} ))
      ]})
    ]})
  )
})

export default ModalError
