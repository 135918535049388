import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import styled from '@emotion/styled'
import PrimaryButton from '~/components/PrimaryButton'
import ReportErrorFeedback from '~/components/ReportErrorFeedback'
import useModal from '~/hooks/useModal'
import {isOldBrowserError} from '~/utils/isOldBrowserError'

const isNotFoundError = (error) => error.name === 'NotFoundError'

const ErrorBlock = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '100%'
})

const Button = styled(PrimaryButton)({
  marginTop: 8
})

const Link = styled('a')({
  color: 'inherit'
})






const ErrorComponent = (props) => {
  const {error, eventId} = props
  console.error(error)
  const {modalPortal, openPortal, closePortal} = useModal()

  if (isNotFoundError(error)) {
    return (
      _jsxs(ErrorBlock, { children: [
        _jsx('div', { children: "Oh no! Seems like you're using Google Translate or a similar extension, which has a bug in it that can crash apps like ours."


        })
        , _jsx('div', { children: "If this continues, please disable the extension"      })
        , _jsx(Button, { onClick: () => window.location.reload(), children: "Refresh the page"  })
      ]})
    )
  }

  const isOldBrowserErr = isOldBrowserError(error.message)
  if (isOldBrowserErr) {
    const url = 'https://browser-update.org/update-browser.html'
    return (
      _jsxs(ErrorBlock, { children: [
        "Oh no! You've found a bug because the browser you're using needs to be updated."
        , _jsx(Button, { children: 
          _jsx(Link, { href: url, target: "_blank", rel: "noreferrer", children: "Update now"

          })
        })
      ]})
    )
  }
  return (
    _jsxs(ErrorBlock, { children: [
      'An error has occurred! We’ve alerted the developers. Try refreshing the page'
      , eventId && _jsx(Button, { onClick: openPortal, children: "Report Feedback" })
      , modalPortal(_jsx(ReportErrorFeedback, { closePortal: closePortal, eventId: eventId,} ))
    ]})
  )
}

export default ErrorComponent
