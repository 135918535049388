import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import styled from '@emotion/styled'
import * as React from 'react'
import makeFieldColorPalette from '../../styles/helpers/makeFieldColorPalette'
import ui from '../../styles/ui'
import StyledError from '../StyledError'

const TextArea = styled('textarea')(({disabled}) => ({
  ...ui.fieldBaseStyles,
  ...ui.fieldSizeStyles.medium,
  ...makeFieldColorPalette('white', !disabled),
  minHeight: '5.75rem',
  ...(disabled && {...ui.fieldDisabled})
}))













const BasicTextArea = (props) => {
  const {autoFocus, disabled, error, name, onBlur, onChange, onPaste, placeholder, value} = props

  return (
    _jsxs(React.Fragment, { children: [
      _jsx(TextArea, {
        autoFocus: autoFocus,
        disabled: disabled,
        name: name,
        placeholder: placeholder,
        onBlur: onBlur,
        onChange: onChange,
        onPaste: onPaste,
        value: value,}
      )
      , error && _jsx(StyledError, { children: error})
    ]})
  )
}

export default BasicTextArea
